<template>
  <div class="main_container col-all-12" v-cloak>
    <div class="_container col-all-12">
      <InformationBanner></InformationBanner>
      <CookieBanner></CookieBanner>
      <router-view />
      <Footer></Footer>
    </div>
  </div>
</template>
<style>
[v-cloak] {
  display: none;
}
body {
  height: 100%;
}
</style>

<style lang="less" src="@/assets/less/global.less"></style>
<style src="@/assets/css/main-flex.css"></style>

<script>
import InformationBanner from '@/components/InformationBanner.vue'
import CookieBanner from '@/components/CookieBanner.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    InformationBanner,
    CookieBanner,
    Footer
  }
}
</script>
